import React, {FC} from 'react';
import moment from 'moment/moment';
import {YEAR_FORMAT} from '@common/constants';
import {IAwardCardProps, ITranslation} from '@common/types';
import Link from 'next/link';
import {useTranslation} from '@common/hooks/useTranslation';
import Text from '@common/components/common/Text';

const AwardCard: FC<IAwardCardProps> = ({item}) => {
  const t = useTranslation('components') as ITranslation['components'];
  const year = item?.date ? moment(item.date).format(YEAR_FORMAT) : null;
  const target = item.url ? '_blank' : '_self';

  return (
    <li className="awards__grid-item">
      <Link
        className="awards__shadow-link"
        href={item.url ?? '#'}
        target={target}
        rel="nofollow noopener noreferrer"
        aria-label={t.award.linkAriaLabel}
        id={item.id}
      />
      <div className="awards__grid-top">
        <h4 className="awards__grid-title"><Text text={item.title}/></h4>
        {item.date && (
          <span className="awards__grid-year">{year}</span>
        )}
      </div>
      <p className="awards__grid-description"><Text text={item.description}/></p>
    </li>
  )
}

export default AwardCard
