import React, {FC, memo} from 'react';
import {EBlockPosition, IHomeAward} from '@redux/types';
import {THomeCount} from '@modules/Home/home.types';
import Step from '@components/PageStep';
import {useTranslation} from '@common/hooks/useTranslation';
import {ITranslation} from '@common/types';
import AwardsGrid from '@modules/Awards/components/AwardsGrid/AwardsGrid';

const HomeAwards: FC<THomeCount<IHomeAward>> = ({count, step, awards}) => {
  const t = useTranslation('home') as ITranslation['home'];

  return (
    <Step
      id="main-awards"
      step={step}
      count={count}
      classNames={['main-awards', 'inner-grid--aside-left']}
      withoutMainClassname={true}
      childrenPosition={EBlockPosition.insideStepDescription}
      link={{
        title: t.awards.buttonTitle,
        titleMobile: t.common.titleMobile,
        url: '/awards'
      }}
    >
      <AwardsGrid items={awards}/>
    </Step>
  )
}

export default memo(HomeAwards)
