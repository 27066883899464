import React, {FC, memo} from 'react';
import {EPage, IReviewsItem, ITranslation} from '@common/types';
import Text from '@components/common/Text';
import Picture from '@components/common/Picture';
import {EComponent} from '@redux/types';
import Icon from '@components/common/Icon';
import {useTranslation} from '@common/hooks/useTranslation';


const ReviewItem: FC<IReviewsItem> = ({
                                        title,
                                        description,
                                        customer,
                                        preview,
                                        page,
                                        actions}) => {
  const t = useTranslation('components') as ITranslation['components'];

  const withoutPreviewClasses = page !== EPage.SOLUTION ? "review__text" : "review__text review__text-big-title"

  const playVideo = () => {
    actions.setApp({modalVideo: {video: preview?.video, open: true}})
  }

  return (
    <>
      {!preview ? (
        <div className={withoutPreviewClasses}>
          <p>
            <Text text={title}/>
          </p>
        </div>
      ) : (
        <>
          <div className="review__picture">
            <Picture media={{__component: EComponent.MEDIA, image: preview.image}} withoutWrapper={true}/>
          </div>
          <button
            className="review__play"
            onClick={playVideo}
            data-review-video-link="v-3/video/video-review-geoscan.mp4"
          >
            <span className="visually-hidden">{t.video.visuallyHidden}</span>
            <Icon name="icon-play" className="review__play-icon" width={50} height={58}/>
          </button>
        </>
      )}
      {description && (
        <div className="review__author">
          {customer && (
            <span className="review__name">{customer}</span>
          )}
          <span className="review__project"><Text text={description}/></span>
        </div>
      )}
    </>
  )
}

export default memo(ReviewItem)
