import React from 'react';
import MainContacts from '@components/MainContacts';
import {useTypedSelector} from '@common/hooks/useTypedSelector';
import HomeTitle from '@modules/Home/components/HomeTitle';
import HomeServices from '@modules/Home/components/HomeServices';
import HomeNews from '@modules/Home/components/HomeNews';
import HomePartners from '@modules/Home/components/HomePartners';
import HomeCases from '@modules/Home/components/HomeProjects';
import HomeProducts from '@modules/Home/components/HomeProducts';
import HomeAwards from '@modules/Home/components/HomeAwards';
import HomeReviews from '@modules/Home/components/HomeReviews';
import HomeMediaLinks from '@modules/Home/components/HomeMediaLinks';
import HomeSlider from '@modules/Home/components/HomeSolutions';
import {
  IHomeAward,
  IHomeCase,
  IHomeNews, IHomePageStep,
  IHomePartner,
  IHomeProduct,
  IHomeReview,
  IHomeService,
  IHomeSolution
} from '@redux/types';


export const Home = () => {
  const {home} = useTypedSelector(state => state.home);
  //@ts-ignore
  let finalStepNumber = home?.steps?.filter((hs: IHomePageStep) => !!hs?.values?.step)?.length || 1;

  return (
    <>
      {home?.title && (
        <HomeTitle title={home.title} statistics={home.statistics}/>
      )}

      {home?.steps.map((hs, index) => {
        if (hs?.values?.step) {
          let entity = hs.values
          const key = `home_${hs.key}`;
          const stepNumber = index + 1;

          switch (hs.key) {
            case 'slider':
              entity = entity as IHomeSolution
              return (
                <HomeSlider
                  key={key}
                  count={stepNumber}
                  slider={entity}
                />
              );
            case 'services':
              entity = entity as IHomeService
              return (
                <HomeServices
                  key={key}
                  index={entity.index}
                  count={stepNumber}
                  step={entity.step}
                  services={entity?.services ?? []}
                />
              );
            case 'news':
              entity = entity as IHomeNews
              return (
                <HomeNews
                  key={key}
                  index={entity.index}
                  count={stepNumber}
                  step={entity.step}
                  news={entity?.news ?? []}
                />
              );
            case 'partners':
              entity = entity as IHomePartner
              return (
                <HomePartners
                  key={key}
                  index={entity.index}
                  count={stepNumber}
                  step={entity.step}
                  partners={entity.partners}
                />
              );
            case 'cases':
              entity = entity as IHomeCase
              return (
                <HomeCases
                  key={key}
                  index={entity.index}
                  count={stepNumber}
                  step={entity.step}
                  projects={entity.projects}
                />
              );
            case 'products':
              entity = entity as IHomeProduct
              return (
                <HomeProducts
                  key={key}
                  index={entity.index}
                  count={stepNumber}
                  step={entity.step}
                  products={entity.products}
                />
              );
            case 'awards':
              entity = entity as IHomeAward
              return (
                <HomeAwards
                  key={key}
                  index={entity.index}
                  count={stepNumber}
                  step={entity.step}
                  awards={entity.awards}
                />
              );
            case 'reviews':
              entity = entity as IHomeReview
              return (
                <HomeReviews
                  key={key}
                  index={entity.index}
                  count={stepNumber}
                  step={entity.step}
                  reviews={entity.reviews}
                />
              );
            default:
              return <></>
          }
        }
      })}

      <MainContacts count={finalStepNumber}/>

      {home?.banner && (
        <HomeMediaLinks banner={home.banner}/>
      )}
    </>
  )
}
