// @ts-ignore
import {ScrollTrigger} from './gsap/ScrollTrigger.min';
// @ts-ignore
import {gsap} from './gsap/gsap.min';

const initSliderScroll = (setSlideIndex: (index: number) => void) => {
  const slider = document.querySelector('[data-slider="scroll"]');

  if (!slider) {
    return;
  }

  // @ts-ignore
  let slides = gsap.utils.toArray('[data-slider="slide-scroll"]');
  const sliderTop = slider.getBoundingClientRect().top + window.scrollY;
  const paginationBtns = slider.querySelectorAll('[data-slider="pagination-btn"]');
  const fractionCurrent = slider.querySelector('[data-slider="fraction-current"]');
  const vpTouch = window.matchMedia('(pointer: coarse)');
  let currentSection = slides[0];
  let time = 0;
  let newTime = 0;

  const scrollPage = (distance: any) => {
    window.scrollTo({
      top: distance,
      behavior: 'smooth',
    });
  };

  const setActiveBtn = (buttonIndex: any) => {
    paginationBtns.forEach((item) => {
      item.classList.remove('is-active');
    });
    paginationBtns[buttonIndex].classList.add('is-active');
  };

  slides.forEach((section: any, i: number) => {
    ScrollTrigger.create({
      trigger: '[data-slider="scroll"]',
      start: () => (i - 0.5) * innerHeight / 2.5 + sliderTop,
      end: () => (i + 0.5) * innerHeight / 2.5 + sliderTop,
      onToggle: (self: any) => self.isActive && setSlide(section, i),
      scrab: vpTouch.matches ? 1 : true,
      fastScrollEnd: 2000,
    });
  });


  function setSlide(newSection: any, i: any) {
    if (!fractionCurrent) {
      return
    }
    fractionCurrent.innerHTML = i + 1;
    setSlideIndex(i)

    if (newSection !== currentSection) {
      newTime = window.performance.now();
      let difference = newTime - time;

      if (difference < 300) {
        setTimeout(() => {
          // @ts-ignore
          gsap.to(slides, {opacity: 0, duration: 0.2});
          // @ts-ignore
          gsap.to(newSection, {opacity: 1, duration: 0.2});
        }, 500);
      }
      time = newTime;

      // @ts-ignore
      gsap.to(currentSection, {
        opacity: 0,
        duration: 0.2,
        onComplete() {
          // @ts-ignore
          gsap.to(newSection, {opacity: 1, duration: 0.2});
        },
      });

      currentSection = newSection;
    }
    setActiveBtn(i);
  }

  paginationBtns.forEach((item) => {
    item.addEventListener('click', function (e: any) {
      let windowHeight = window.innerHeight;
      let buttonIndex = Array.from(paginationBtns).indexOf(e.target);
      setActiveBtn(buttonIndex);
      scrollPage(sliderTop + windowHeight / 2.5 * buttonIndex);
    });
  });

  return time
};

export {initSliderScroll};
