import React, {FC, memo} from 'react';
import Link from 'next/link';
import {IReviewsProps} from '@common/types';
import {useActions} from '@common/hooks/useActions';
import {useTypedSelector} from '@common/hooks/useTypedSelector';
import ReviewItem from '@components/ReviewsGrid/ReviewItem';


const ReviewsGrid: FC<IReviewsProps> = ({reviews, type}) => {
  const actions = useActions();
  const {page} = useTypedSelector(state => state.app);

  return (
    <div className={`review ${type === 'services' ? 'review__links' : ''}`}>
      <ul className="review__list">
        {reviews?.map((item, index) => {
          let classNames = ['review__item', `review__item--bg-${item?.color ?? 'gray'}`]
          if (!item.cardType) {
            classNames.push('review__item--type-big')
          }
          if (item.preview) {
            classNames.push('review__item--type-video')
          }
          if (item.cardType === 'rounded-right') {
            classNames.push('review__item--type-big review__item--radius-right')
          }
          if (item.cardType === 'rounded') {
            classNames.push('review__item--type-big review__item--center review__item--radius-round')
          }
          if (item.cardType === 'circle') {
            classNames.push('review__item--center review__item--radius-round')
          }
          if (type === 'services') {
            classNames.push('review__item--text-big')
          }
          return (
            <li className={classNames.join(' ')} key={`review_${item.title}_${index}`}>
              {(type === 'services' && !item?.onlyPreview) ? (
                <Link className="review__link" href={`/services/${item?.slug}`}>
                  <ReviewItem title={item.title} description={item.description} page={page || null} actions={actions}/>
                </Link>
              ) : (
                <ReviewItem
                  title={item.title}
                  page={page || null}
                  actions={actions}
                  description={item.description}
                  customer={item.customer || ''}
                  preview={item.preview || null}
                />
              )}
            </li>
          )
        })}
      </ul>
    </div>
  )
}


export default memo(ReviewsGrid)
