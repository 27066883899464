import React, {FC} from 'react';
import {EBlockPosition, IHomeService} from '@redux/types';
import Step from '@components/PageStep';
import {useTranslation} from '@common/hooks/useTranslation';
import {ITranslation} from '@common/types';
import {THomeCount} from '@modules/Home/home.types';
import Link from 'next/link';
import Text from '@common/components/common/Text';


const HomeServices: FC<THomeCount<IHomeService>> = ({count, step, services}) => {
  const t = useTranslation('home') as ITranslation['home']

  return (
    <Step
      step={step}
      count={count}
      classNames={['services']}
      childrenPosition={EBlockPosition.insideStepDescription}
      link={{
        title: t.services.buttonTitle,
        titleMobile: t.common.titleMobile,
        url: '/services'
      }}
    >
      <ul className="services__list">
        {services?.map((service, index) => {
          const liClasses = `service-item-${service?.slug}-${index}`;
          const linkClasses = `services__link ${service.meta?.onlyPreview ? 'only-preview' : ''}`;
          const linkUrl = service?.slug && !service.meta?.onlyPreview ? `/services/${service.slug}` : '';

          return (
            <li key={liClasses} className="services__item">
              <Link className={linkClasses} href={linkUrl}>
                <Text text={service.title}/>
              </Link>
            </li>
          )
        })}
      </ul>
    </Step>
  )
}

export default HomeServices
