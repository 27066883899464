import React, {FC} from 'react';
import {IAwardsGridProps, ITranslation} from '@common/types';
import Link from 'next/link';
import moment from 'moment';
import {YEAR_FORMAT} from '@common/constants';
import {IAward} from '@redux/types';
import AwardCard from '@modules/Awards/components/AwardsGrid/AwardCard';
import {useTranslation} from '@common/hooks/useTranslation';
import Text from '@common/components/common/Text';


const AwardsGrid: FC<IAwardsGridProps> = ({items = []}) => {
  const t = useTranslation('components') as ITranslation['components']

  return (
    <div className="awards main-awards__awards">
      {items?.map((award: IAward, index) => {
        const key = `award_item_${award.title}_${index}`;
        const year = moment(award.date).format(YEAR_FORMAT);

        if (!award.grid?.length) {
          const target = award.url ? '_blank' : '_self';
          const url = award.url ?? '/#';

          return (
            <Link
              key={key}
              className="awards__item"
              href={url}
              target={target}
              rel="nofollow noopener noreferrer"
              aria-label={t.award.linkAriaLabel}
              id={award.id}
            >
              <div className="awards__box">
                <h3 className="awards__name"><Text text={award.title}/></h3>
                {award.description && (
                  <p className="awards__place"><Text text={award.description}/></p>
                )}
              </div>
              {award.date && (
                <span className="awards__year">{year}</span>
              )}
            </Link>
          )
        } else {
          return (
            <ul key={key} className="awards__grid">
              {award.grid.map(a => (
                <AwardCard key={a.title} item={a}/>
              ))}
            </ul>
          )
        }
      })}
    </div>
  )
}

export default AwardsGrid
